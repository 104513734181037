import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import {

  Hero,
  Partners,

  Services,
  Users,
} from './components';
import { Divider } from '@mui/material';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const WebBasic = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
          
        }}
      >
        <Container id="Gopalkrishna">
          <Hero />
          <Divider />
        </Container>

      </Box>
      <Container id="Shivshankar">
        <Services />
        <Divider />
      </Container>


      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
          
        }}
      >
      <Container id="Sheeja">
        <Partners />
        <Divider />
      </Container>
</Box>
      {/* <Container id='Nikitaa'>
        <Users />
        <Divider />
      </Container> */}


    </Main>
  );
};

export default WebBasic;
