import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
// import p1 from 'assests/Photos/Messages/1.jpg';
// import p2 from 'assests/Photos/Messages/dean.jpg';

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/home-static/messages/1.webp`;
  const p2 = `${config.base_image_url}/home/home-static/messages/Chairman.webp`;

  return (
    <Grid container spacing={4} >

      <Grid item xs={12} md={6}>
        <Box
          height={1}
          width={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box height={1} width={1} maxWidth={500}>
            <Box
              component={'img'}
              src={
                p2
              }
              width={1}
              height={0.7}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item container alignItems={'justify'} xs={12} md={6}>

        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Typography fontWeight={700} variant={'h4'} align={'center'} >
            Dr. Shivshankar,
            Chairman,
            NPS Yeshwanthpur
          </Typography>
          <br />
          <br />

          <Box marginBottom={2}>
            <Typography
              
              color="text.primary"
              align='justify'
            >
             There is no letter that cannot be used to form a manthra. There is no root that cannot be made into a medicine. There is no person who is unworthy, What we lack is people who can make mathra out of Letters, medicine out of roots and bring out true potential out of individuals.
<br/><br/>
The above subhashitha teaches us the importance of bringing out the best in each child that forms the basis of education.
<br/><br/>
National public school, yeshwanthpur boosts confidence and teaches each individual to establish and maintain friendships and helps and motivates each one to learn how to work together as a team which is a primary tenet of any successful society. Albert Einstein rightly observes “Education is not only learning of facts, it is rather the training of the mind to think”. The function of education is to teach one to think intensively and to think critically. Education is the basis for all progress in the world.


            </Typography>
          </Box>

        </Box>
      </Grid>
      <Grid item container alignItems={'justify'} xs={12} md={6}>
      <Box
          height={1}
          width={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box height={1} width={1} maxWidth={500} maxHeight={100}>
            <Box
              component={'img'}
              src={
                p1
              }
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid  item container alignItems={'justify'} xs={12} md={6}>
<Typography color="text.primary"
              align='justify'>
National public school, yeshwanthpur, continually strives to identify and respond to the volatile demands of the education sector. Our aim is to offer our students a relevant and self-motivated curriculum which will help them achieve brighter and more secure prospects for their future. We focus to inculcate values like humanity, compassion, courage and integrity. Along with hoping to provide our children a sustainable environment through their formative years, our objective is to continue to be recognised as torch bearers in the field of education. I am positive that our students will achieve great heights and sculpt out a future not only for themselves but for the world as well.
<br/><br/>
I take immense pleasure in welcoming parents and students to be a part of our family to share ideas and aspirations as we strive to build through an enriching and enjoyable educational experience for our children.

</Typography>
      </Grid>

    </Grid>
  );
};

export default Hero;
