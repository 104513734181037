//SheejaManoj
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import p3 from 'assests/Photos/Messages/principal_administration_ms_sheeja_manoj.jpg';
// import p6 from 'assests/Photos/fes/SHEEJAMANOJ.jpg'



const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);
const p6 = `${config.base_image_url}/home/home-static/messages/Sheeja.webp`;


  return (
    <Grid container spacing={4} >
      <Grid item container alignItems={'justify'} xs={12} md={6} >
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
          Ms. Sheeja Manoj,
          Principal 
        </Typography>
<br/>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography
             
              color="text.primary"
              align={'justify'}
            >
             Every bud conceals fragrance, colours and a promise within its sepals. The external stimulus only facilitates to strengthen, unfurl and manifest the beauty of flowers.
<br/><br/>
At National Public School Yeshwanthpur we breathe this axiom and strongly believe that every child is a bud and waits for the opportune moment to blossom. Every child has a hidden potential, an innate ability which can be unleashed, when provided a conducive learning environment. The true purpose of education lies in giving children the opportunities to experiment, the latitude to identify and the space to explore themselves.
<br/><br/>
National Public School Yeshwanthpur essentially recognizes every child to be unique and their individuality is celebrated. The motivated educators at our school are adept at facilitating experiences, nurturing talents, evolving the skill sets, stimulating the intellect and gently leading the children towards excellence. They adopt, adapt and evolve new methodology to be in tune with the changing times. The younger generation are imbued with an ability to learn, apply, unlearn, and relearn so that they are equipped with a competitive edge as they step into the millennium as global citizens.
            </Typography>
          </Box>

        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          height={1}
          width={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box height={1} width={1} maxWidth={500} maxHeight={400}>
            <Box
              component={'img'}
              src={p6}
              
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item container alignItems={'justify'} xs={12} md={12}>
        <Typography
        
        color="text.primary"
        align={'justify'}>
        It has been our constant endeavour to provide holistic development to every student so that they imbibe values, developed stimulated intellect and contribute to the growth of the society. Every student who leaves the portals of this school is infused with self-discipline, great sense of self awareness, accountability for outcomes, strength of character and a shared responsibility towards the community. Above all, the real education is a journey of transforming the ‘human being’ in to ‘being human’.
<br/><br/>
A very important factor that shapes a child’s quest of learning and excellence is the motivation from parents. The constant support of the parents in a warm , gentle but emphatic manner will help children grow into an independent and confident individual. A fruitful collaboration between parents and teachers will definitely warrant a young new generation who are equipped to chase their own dreams, materialise their aspirations, who will be instrumental as a medium for change and emerge as leaders in this dynamically changing world.
<br/><br/>
Let us all embark together in this voyage of making our children resilient in the face of unforeseen circumstances, foster curiosity and creativity, a firm grounding to do what is right, encourage them to appreciate the quest itself and bestow upon them the power to unlock their own future.
<br/><br/>
<strong>Dear students for you to ponder…<br/>
'The will to win, the desire to succeed, the urge to reach your full potential…these are the keys that will unlock the door to personal excellence.' -Confucius</strong>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Hero;
