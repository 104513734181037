//Dr. K.P. Gopalkrishna

/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
// import p1 from 'assests/Photos/Messages/chairman.jpg'

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

const [config, setConfig] = useState({ base_image_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_url}/home/home-static/static-images/KP-gopal.webp`;

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems={'justify'} xs={12} md={6}>
        <Typography fontWeight={700} variant={'h4'} align={'center'} >
          Dr. K.P. Gopalkrishna
          Chairman, NPS Group
        </Typography>
<br/>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              
              color="text.primary"
              align="justify"
            >
             As the world approaches a new age of synthesis, knowledge alone cannot suffice;
but demands a broader vision towards fulfilling the challenges of educating the
present generation.<br/><br/>
Teachers, planners, educators and parents have an important role in restructuring
the policy of education, which helps children to become independent thinkers,
creators and managers.<br/><br/>
NPS, is a vibrant and welcoming learning community committed to creating the
best possible educational experience for every child. To facilitate this, we provide
a broad, well-balanced and relevant curriculum, encouraging positive social
behavior and emphasizing values.
            </Typography>
          </Box>

        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          height={1}
          width={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box height={1} width={1} maxWidth={500}>
            <Box
              component={'img'}
              src={
                p1
              }
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item container alignItems={'justify'} xs={12} md={6}>
        <Typography  color="text.primary" align="justify">
        "Curriculum for Excellence" for learners from age 3 to 18 has been evolved in NPS, to mould children to become, successful learners, confident individuals, responsible citizens, effective contributors and visionary leaders.
<br/><br/>
I welcome parents and students to be a part of this family to share common ideas and aspirations as we build through a challenging and enriching educational experience for our students.
        </Typography>

      </Grid>
    </Grid>
  );
};

export default Hero;
